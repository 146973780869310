import React from "react"
import { graphql } from 'gatsby'
import { Container } from 'react-bootstrap'



const Test = ({data}) => {

    return <>
        <Container style={{marginTop: "10vh"}}>
            <p>test</p>
        </Container>
    </>
}



export default Test;


export const query = graphql`
query {
    brown_on: allFile(filter: {relativeDirectory: {eq: "spin/brown_on"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
    brown_off: allFile(filter: {relativeDirectory: {eq: "spin/brown_off"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
}`

